<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课程列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="课程名称">
                <el-input v-model="search.lesson_name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上下架状态">
                <el-select v-model="search.publish_state" placeholder="请选择类型" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="上架" :value="1"></el-option>
                  <el-option label="下架" :value="0"></el-option>
                  <el-option label="审核中" :value="2"></el-option>
                  <el-option label="不通过" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运动品类">
                <el-input v-model="search.lesson_motion_category"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="运动效果">
                <el-input v-model="search.lesson_target_category"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">显示搜索
            </el-button>
            <el-button v-if="is_auth('lesson.lesson.issave')" icon="el-icon-plus" size="medium" type="primary"
              @click="tosee()">添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="company_name" label="所属企业" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="lesson_name" label="课程名称" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.lesson_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_name" label="课程类型" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lesson_type === 1" size="mini">团课</el-tag>
            <el-tag v-if="scope.row.lesson_type === 2" size="mini">训练营</el-tag>
            <el-tag v-if="scope.row.lesson_type === 3" size="mini">私教</el-tag>
            <el-tag v-if="scope.row.lesson_type2 === 1" size="mini" type="success">大团课</el-tag>
            <el-tag v-if="scope.row.lesson_type2 === 2" size="mini" type="success">小团课</el-tag>
            <el-tag v-if="scope.row.lesson_type2 === 3" size="mini" type="success">小团私</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_motion_category" label="运动品类" min-width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag :key="item.id" v-for="item in scope.row.lesson_motion_category" class="form-tag_colour-tag"
              effect="dark" size="mini">
              {{ item.name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_target_category" label="运动效果" min-width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag :key="item.id" v-for="item in scope.row.lesson_target_category" class="form-tag_colour-tag"
              effect="dark" size="mini">
              {{ item.name }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="lesson_duration" label="课程时长">
          <template slot-scope="scope">
            <span>{{ scope.row.lesson_duration }}分钟</span>
          </template>
        </el-table-column>
        <el-table-column prop="price_indication" label="指导价格">
          <template slot-scope="scope">
            <span>￥{{ scope.row.price_indication }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="examine_status" label="审核状态">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.examine_status === 0">审核中</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 1" type="success">审核通过</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 2" type="danger">审核失败</el-tag>
            <el-tag size="mini" v-if="scope.row.examine_status === 3" type="danger">违规下架</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="publish_state" label="上下架状态" width="100">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.publish_state === 0" type="warning">下架</el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 1" type="success">上架</el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 2" type="warning">审核中</el-tag>
            <el-tag size="mini" v-if="scope.row.publish_state === 3" type="danger">不通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="120" show-overflow-tooltip>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="390">
          <template slot-scope="scope">
            <el-button v-if="is_auth('lesson.lesson.getinfo')" @click="tosee(scope.row.lesson_uuid)" size="mini">查看
            </el-button>
            <el-button v-if="is_auth('lesson.lesson.getinfo')" @click="toEdit(scope.row.lesson_uuid)" size="mini">编辑
            </el-button>
            <el-button v-if="is_auth('lesson.lesson.upapply') &&
              scope.row.publish_state === 0 &&
              scope.row.examine_status === 1
              " @click="
    operation_tip(scope.row.lesson_uuid, scope.row.lesson_name, 'upapply')
    " type="success" size="mini">上架发布
            </el-button>
            <el-button v-if="is_auth('lesson.lesson.withdraw') &&
              scope.row.publish_state === 1
              " @click="
    operation_tip(scope.row.lesson_uuid, scope.row.lesson_name, 'withdraw')
    " type="warning" size="mini">取消发布
            </el-button>
            <el-button @click="
              operation_tip(scope.row.examine_reason, scope.row.lesson_name, 'watch')
              " size="mini" type="danger"
              :disabled="scope.row.examine_status !== 3 && scope.row.examine_status !== 2">查看原因
            </el-button>
            <el-button v-if="is_auth('lesson.lesson.isdel')" @click="
              operation_tip(scope.row.lesson_uuid, scope.row.lesson_name, 'isdel')
              " type="danger" size="mini" :disabled="scope.row.is_dels > 0">删除
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page_name: "课程",
      loading: true, // 加载状态
      issearch: true, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
    };
  },
  // 创建
  created() {
    this.init();
  },
  // 安装
  mounted() { },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        lesson_name: "",
        publish_state: "",
        lesson_tag_s: "",
        lesson_motion_category: "",
        lesson_target_category: "",
      };
      this.is_search();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "lesson.lesson.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    // 操作提示
    operation_tip(lesson_uuid = "", lesson_name = "", operation = "") {
      let tip = "";
      let options = {};
      // 上架
      if (operation === "upapply") {
        tip = "上架【" + lesson_name + "】？";
      }
      // 下架
      if (operation === "withdraw") {
        tip = "下架【" + lesson_name + "】？";
      }
      // 删除课程
      if (operation === 'isdel') {
        tip = "删除【" + lesson_name + "】？"
      }
      // 查看原因
      if (operation === 'watch') {
        tip = lesson_uuid
      }

      // 弹出二次确认
      this.$confirm(tip, operation === 'watch' ? '查看原因' : "确认信息", options)
        .then(() => {
          if (operation !== 'watch') {
            this.isoperation(lesson_uuid, operation);
          }
        })
        .catch(() => { });
    },
    // 操作
    isoperation(lesson_uuid = "", operation = "") {
      let postdata = {
        api_name: "lesson.lesson." + operation,
        token: this.Tool.get_l_cache("token"),
        lesson_uuid: lesson_uuid,
      };
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 查看
    tosee(lesson_uuid = "") {
      if (lesson_uuid === "") {
        this.$router.push({ path: "/lesson/lesson/Edit" });
      } else {
        this.$router.push({
          path: "/lesson/lesson/Info",
          query: { lesson_uuid: lesson_uuid },
        });
      }
    },
    // 前往编辑
    toEdit(lesson_uuid = "") {
      this.$router.push({
        path: "/lesson/lesson/Edit",
        query: { lesson_uuid: lesson_uuid },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-tag_colour-div {
  min-height: 200px;
}
</style>
